import React from "react"
import { Link } from "gatsby"
import { TheLayout } from "x"

const SuccessPage = () => (
  <TheLayout seoTitle="Success!">
    <h1>Hi from the success listings page</h1>
    <p>Welcome to page 2</p>
    <Link to="/">Go back to the homepage</Link>
  </TheLayout>
)

export default SuccessPage
